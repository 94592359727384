<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1831 15.1931C19.2186 15.2105 19.2403 15.2211 19.2451 15.2234C19.4869 15.3395 19.678 15.4312 19.7889 15.6135C19.9508 15.8815 19.8866 16.6201 19.6433 17.2962C19.3345 18.1544 17.9767 18.858 17.3588 18.9128L17.2681 18.9214C17.1253 18.9355 16.9633 18.9514 16.761 18.9514C16.2795 18.9514 15.4325 18.8688 13.6439 18.1544C11.7861 17.4122 9.95348 15.8212 8.48341 13.6742C8.45799 13.6371 8.44047 13.6111 8.43093 13.5984C8.04706 13.0925 7.15299 11.7622 7.15299 10.3533C7.15299 8.78795 7.9023 7.89783 8.34522 7.61858C8.76289 7.3553 9.70755 7.23094 9.88101 7.22362C9.9839 7.21928 9.99476 7.21807 10.0057 7.21774C10.0098 7.21761 10.014 7.21761 10.0235 7.21761C10.3896 7.21761 10.653 7.43977 10.8524 7.91691C10.8723 7.96436 10.9323 8.10881 11.0127 8.30218C11.2672 8.9146 11.7256 10.0177 11.7588 10.0841C11.812 10.1884 11.9767 10.5117 11.7839 10.8947L11.7425 10.9779C11.6637 11.1377 11.5953 11.2763 11.4425 11.4541C11.3934 11.5109 11.3434 11.5711 11.2934 11.6312C11.1881 11.758 11.0789 11.8894 10.9784 11.9888C10.944 12.0233 10.8774 12.0901 10.8702 12.1166C10.8702 12.1166 10.8701 12.1162 10.8702 12.1166C10.8708 12.1207 10.8745 12.1443 10.9011 12.1896C11.1073 12.5386 12.6111 14.6081 14.5923 15.4687C14.6788 15.5063 14.9646 15.624 14.9812 15.624C14.9996 15.624 15.0303 15.5932 15.0529 15.5675C15.2254 15.3725 15.7839 14.7196 15.9684 14.4454C16.1316 14.2013 16.3411 14.077 16.5905 14.077C16.7431 14.077 16.8867 14.124 17.0207 14.1721C17.3171 14.2788 18.8522 15.0309 19.1831 15.1931Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.948578 13.0159C0.948578 6.20644 6.53047 0.666504 13.3915 0.666504C20.252 0.666504 25.8333 6.20644 25.8333 13.0159C25.8333 19.8247 20.252 25.364 13.3916 25.364C11.334 25.364 9.30093 24.8566 7.5021 23.8955L0.928591 25.9844C0.895855 25.9948 0.862297 25.9998 0.828985 25.9998C0.742046 25.9998 0.656916 25.9653 0.594158 25.9012C0.507219 25.8127 0.477609 25.6828 0.517418 25.5652L2.65455 19.2611C1.53799 17.3742 0.948578 15.2184 0.948578 13.0159ZM7.96822 21.1747C9.58017 22.2325 11.4554 22.7915 13.3915 22.7915C18.8254 22.7915 23.2462 18.4063 23.2461 13.0159C23.2461 7.62495 18.8253 3.23899 13.3914 3.23899C7.95695 3.23899 3.53562 7.62495 3.53562 13.0159C3.53562 15.088 4.1845 17.0697 5.41202 18.7469C5.47535 18.8335 5.49254 18.9454 5.45816 19.0469L4.39515 22.1831L7.68807 21.1363C7.72072 21.126 7.75428 21.1209 7.78776 21.1209C7.85109 21.1209 7.91401 21.1391 7.96822 21.1747Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class WhatsappOutline extends Vue {}
</script>
